import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { MegaMenuFavoritesList } from 'frontend-container/components/Menu/components/MegaMenu/MegaMenuFavoritesList';
import { RightMenuItem } from 'frontend-container/components/Menu/components/MegaMenu/useRightColumnConfig';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  AlignItems,
  ButtonPattern,
  ButtonSize,
  FlexDirection,
  IconName,
  MenuGroupSlot,
} from '@ac/web-components';

interface Props {
  isMac: boolean;
  sortedItems: MenuElement[][];
  rightMenuItems: RightMenuItem[];
  isEditMode: boolean;
  toggleManageMode: () => void;
  onCloseAndSaveCallback: () => void;
  favoritesItemsIds: string[];
  isStandalone: boolean;
  isBottomButtonVisible?: boolean;
  toggleShowFavoriteOnly?: () => void;
}

export const MegaMenuFavoritesGroup = ({
  isMac,
  sortedItems,
  rightMenuItems,
  isEditMode,
  toggleManageMode,
  onCloseAndSaveCallback,
  favoritesItemsIds,
  isStandalone,
  isBottomButtonVisible,
  toggleShowFavoriteOnly,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <ac-slot-container slot={MenuGroupSlot.header}>
        <ac-menu-favorite
          slot={MenuGroupSlot.header}
          id="favorites"
          label={t('MENU.MAIN_MENU.FAVORITES.TITLE')}
          icon={IconName.star}
          onClickCallback={toggleManageMode}
          isEditMode={isEditMode}
          isStandalone={isStandalone}
        />
      </ac-slot-container>
      <ac-slot-container slot={MenuGroupSlot.items}>
        <MegaMenuFavoritesList
          isMac={isMac}
          favoritesItemsIds={favoritesItemsIds}
          sortedItems={sortedItems}
          rightMenuItems={rightMenuItems}
          isEditMode={isEditMode}
          onCloseCallback={onCloseAndSaveCallback}
          isStandalone={isStandalone}
        />
        {isBottomButtonVisible && (
          <ac-flex
            direction={FlexDirection.column}
            class="ac-padding-left-xl"
            slot={MenuGroupSlot.items}
          >
            <ac-flex alignItems={AlignItems.center}>
              <ac-button
                pattern={ButtonPattern.tertiary}
                onClick={(event): void => {
                  toggleShowFavoriteOnly?.();
                  event.stopPropagation();
                }}
                size={ButtonSize.sm}
                class="ac-padding-left-xxs"
              >
                <ac-button-content
                  text={
                    isStandalone
                      ? t('MENU.MAIN_MENU.FAVORITES.SHOW_FULL_MENU')
                      : t('MENU.MAIN_MENU.FAVORITES.SHOW_FAVORITES_ONLY')
                  }
                />
              </ac-button>
            </ac-flex>
          </ac-flex>
        )}
      </ac-slot-container>
    </Fragment>
  );
};
